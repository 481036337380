@import "core/variables";
@import "core/mixins";
@import "core/misc";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-react-datetime";
@import "plugins/plugin-react-slick";

@font-face {
  font-family: "SpoqaHanSansNeo";
  font-style: normal;
  font-weight: 400;
  src: local("SpoqaHanSansNeo"),
    url("../fonts/SpoqaHanSansNeo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SpoqaHanSansNeo";
  font-style: normal;
  font-weight: 600;
  src: local("SpoqaHanSansNeo"),
    url("../fonts/SpoqaHanSansNeo-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "SpoqaHanSansNeo";
  font-style: normal;
  font-weight: 800;
  src: local("SpoqaHanSansNeo"),
    url("../fonts/SpoqaHanSansNeo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "ConceptBold";
  src: local("ConceptBold"), url("../fonts/Concept-Bold.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: SpoqaHanSansNeo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    SpoqaHanSansNeo, SpoqaHanSansNeoBold, monospace;
}
